import React from "react"
import { Link } from "gatsby"

export default function Layout({ children }) {
  return (
    <div id="layout-wrapper">
    <div id="wrapper1">
      <div id="mailcol">
        <div id="container">
          <header>
            <div id="heading_1">
              <div class="clearfix"> </div>
            </div>
            <div id="heading_2">
              <div id="h_solution_wrapper">
                <div id="h_solution">
                  <strong>solution</strong> so·lu·tion (s-lshn) - the act or a
                  means of removing or effectively dealing with a problem or
                  difficulty
                </div>
                <div class="clearfix"></div>
              </div>
              <div id="h_menubox">
                <div id="layout-navigation" class="group">
                  <div class="zone zone-navigation">
                    <nav>
                      <ul class="menu menu-main">
                        <li>
                          {" "}
                          <Link to="/" activeClassName="active">Home</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to="/contact" activeClassName="active">Contact</Link>
                        </li>
                        <li>
                          {" "}
                          <Link to="/apps" activeClassName="active">Apps</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>

            <div id="heading_3">
              <div class="clearfix"></div>
            </div>
          </header>
          <div id="main">
          {children}
          </div>
        </div>
        <div id="layout-footer" class="group">
          <footer id="footer">
            <div id="footer-sig" class="group">
              <div class="zone zone-footer">
                <article class="widget-html-widget widget">
                  <p>CRW | Solutions | 2020</p>
                </article>
              </div>
            </div>
            <div class="clearfix"></div>
          </footer>
        </div>
      </div>
    </div>
  </div>
  )
}