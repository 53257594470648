import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default function Contact() {
  function onSubmit(token) {
    document.getElementById("demo-form").submit()
  }

  return (
    <Layout>
      <h1>Contact</h1>
      <script src="https://www.google.com/recaptcha/api.js"></script>
      <p><strong>Heeft u een vraag over kinderliedjes? Kijkt u eerst op <Link to="/kinderliedjes-veel-gestelde-vragen">kinderliedjes: veel gestelde vragen</Link></strong></p>
      <br/>
      <p>Vul het volgende formulier in en klik op Send om de informatie op te sturen.</p>
      <br/>
      <br/>
      <form method="post" action="https://getform.io/f/be2f564c-e52d-4399-9b95-aa40ba78f6d1" class="contact-form" >
        <fieldset>
          <legend>Contact Us</legend>
          <ol>
            <li>
              <label for="name">Name</label>
              <input
                id="name"
                class="text required"
                name="name"
                type="text"
                required
              />
            </li>
            <li>
              <label for="email">Email</label>
              <input
                id="email"
                class="text required"
                name="email"
                type="email"
                required
              />
            </li>
            <li>
              <label for="message">Details</label>
              <textarea
                id="message"
                class="text"
                name="message"
                cols="50"
                rows="10"
                required
              ></textarea>
            </li>
          </ol>
        </fieldset>

        <button
          class="g-recaptcha"
          data-sitekey="reCAPTCHA_site_key"
          data-callback="onSubmit"
          data-action="submit"
        >
          Send
        </button>
      </form>
    </Layout>
  )
}
